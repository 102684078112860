<template>
  <div
    class="singleChoiceItem"
    @click="chioseChange"
    key=""
    :class="{ ischecked: isChecked }"
  >
    <div class="itemIcon"></div>
    <span>{{ label }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number, Boolean],
    val: [String, Number],
    label: String,
  },
  model: {
    prop: "value",
    event: "upData",
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    isChecked() {
      if (this.value == this.val) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    setValue(v) {
      this.$emit("upData", v);
    },
    chioseChange() {
      this.setValue(this.val);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.singleChoiceItem {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.3s;
  &:last-child {
    margin-bottom: 0;
  }
  .itemIcon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--Gray-300, #d0d5dd);
    margin-right: 8px;
  }

  span {
    color: var(--Gray-700, #344054);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
.singleChoiceItem.ischecked {
  border-color: #344054 !important;
  .itemIcon {
    border: solid 5px #1767d0;
  }
}
</style>
