<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f763cd226ae.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("登录详细信息") }}
            </div>
            <div class="chioseTitleFont">
              {{ $fanyi("我们几乎完成了!填写您的详细信息以登录Rakumart。") }}
            </div>
          </div>

          <div class="inputList">
            <el-form
              ref="formRef"
              :model="ops.formregister"
              :rules="formRules"
              @validate="validateMethods"
            >
              <!-- 邮箱需要用 xxxxx@rakumart.test.cn 格式 -->
              <div class="formRow">
                <el-form-item
                  :label="$fanyi('电子邮件')"
                  prop="email"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.email"
                    autocomplete="off"
                    :placeholder="$fanyi('请输入邮箱')"
                  />
                </el-form-item>
              </div>

              <div class="formRow formPasswordBox">
                <el-form-item
                  :label="$fanyi('密码')"
                  prop="password"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.password"
                    :placeholder="$fanyi('输入安全密码')"
                    type="password"
                  ></el-input>
                </el-form-item>
                <div class="inputTipBox ">
                  <p>{{ $fanyi("*至少使用8个字符。它包括:") }}</p>
                  <ul>
                    <li>{{ $fanyi("大写和小写字母。") }}</li>
                    <li>{{ $fanyi("数字") }}</li>
                    <li>
                      {{ $fanyi("特殊符号@ # $ % & * !？ _ - + = / | ~ ^ ()") }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('重新输入密码')"
                  prop="repassword"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.repassword"
                    :placeholder="$fanyi('重新输入密码')"
                    type="password"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-button
            type="primary"
            class="ContinuarBtn"
            :class="{ isdisabled: btnDisable }"
            @click="!btnDisable ? next() : ''"
            >{{ $fanyi("继续") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
import oDialog from "@/components/public/Dialog.vue";
export default {
  data() {
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          //console.log("checkRegisterField", res);
          if (res.success == false) {
            callback(new Error(this.$fanyi("已被使用")));
          } else {
            callback();
          }
        });
    };
    // 密码校验
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi("请输入密码")));
      } else {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%&*!?_\-+=\/|~^()]).{8,}$/;
        if (regex.test(value)) {
          callback();
        } else {
          callback(
            new Error(
              this.$fanyi(
                "输入密码吗?包含至少8个大写、小写、数字和至少一个特殊符号的字符。"
              )
            )
          );
        }
      }
    };
    // 重新输入密码校验
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== ops.formregister.password) {
        callback(new Error(this.$fanyi("两次输入密码不一致!")));
      } else {
        callback();
      }
    };
    return {
      ops,
      smallStep: 1,
      phoneKeyKey: 23,
      formRules: {
        email: [
          ops.inputRequireVer,
          {
            type: "email",
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
        password: [
          ops.inputRequireVer,
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        repassword: [
          ops.inputRequireVer,
          {
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },

      formRegisterStatus: { mobile: "" }, //选项是否正确输入
      Countdown: 60,
      timer: null,
    };
  },
  components: {
    mcheckBox,
    mradio,
    oDialog,
  },
  computed: {
    btnDisable() {
      let flog = false;
      Object.keys(this.formRegisterStatus).forEach((key) => {
        this.formRegisterStatus[key] == -1 ? (flog = true) : "";
      });
      return (
        !this.ops.formregister.email ||
        !this.ops.formregister.password ||
        !this.ops.formregister.repassword ||
        flog
      );
    },
  },
  created() {},
  methods: {
    validateMethods(prop, status) {
      // console.log(prop, status);
      this.$set(this.formRegisterStatus, prop, status ? 1 : -1);
      //console.log(this.formRegisterStatus[prop]);
    },
    goBack() {
      this.ops.step--;
    },
    next() {
      if (this.ops.Countdown != 60) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.ops.Countdown = 60;
      }
      if (!this.ops.formregister.email) {
        this.$message.warning(this.$fanyi("请输入邮箱"));
        return false;
      }
      let list = {
        occasion: "register",
        tool: "email",
        number: this.ops.formregister.email,
        area_code: this.ops.formregister.country_phone,
      };
      this.Countdown = 60;
      this.$api.sendVerificationCode(list).then((res) => {
        this.show = true;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.ops.step = 5;

        this.timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            if (this.timer) {
              clearInterval(this.timer);
            }
          }
        }, 1000);
      });
    },

    // 手动触发表单验证
    validateField(type) {
      if (this.ops.formregister[type])
        this.ops.formregister[type] = this.ops.formregister[type].trim();
      this.$refs.formRef.validateField(type, (v) => {});
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  width: 100%;
  @extend .dip;
  .stepCon {
  }
}
.humanMachineVBox {
  .humanMachineVImgBox {
    display: flex;
    padding: 0 35px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    img {
      width: 300px;
    }
    span {
      // width: 120px;
      margin-left: 20px;
      font-size: 20px;
      text-decoration: underline;
      color: #419dfb;
      cursor: pointer;
    }
  }
}
</style>
