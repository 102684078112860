<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6708a0d870628.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("皇后的细节") }}
            </div>
            <div class="chioseTitleFont">
              {{ $fanyi("输入您公司的详细信息") }}
            </div>
          </div>

          <div class="inputList">
            <el-form
              ref="formRef"
              :model="ops.formregister"
              :rules="formRules"
              @validate="validateMethods"
            >
              <div class="formRow">
                <el-form-item
                  :label="$fanyi('您的公司/业务名称')"
                  :rules="ops.inputRequireVer"
                  prop="company_name"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.company_name"
                    :placeholder="$fanyi('您的公司或企业名称')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('网站') + ' (' + $fanyi('如果你') + ')'"
                  prop="company_web"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.company_web"
                    :placeholder="$fanyi('选择您公司的行业')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('业务类型')"
                  :rules="{
                    required: true,
                    message: $fanyi('此字段为必填项'),
                    trigger: 'blur',
                  }"
                  prop="user_type"
                  class="inputOpt"
                >
                  <el-select
                    filterable
                    name=""
                    clearable
                    @change="validateField('user_type')"
                    :placeholder="$fanyi('选择业务类型')"
                    v-model="ops.formregister.user_type"
                  >
                    <el-option
                      :label="$fanyi('我是公司的')"
                      :value="$fanyi('我是公司的')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('我是自主的')"
                      :value="$fanyi('我是自主的')"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('您的主要业务部门')"
                  :rules="{
                    required: true,
                    message: $fanyi('此字段为必填项'),
                    trigger: 'blur',
                  }"
                  prop="industry_type"
                  class="inputOpt"
                >
                  <el-select
                    filterable
                    name=""
                    clearable
                    @change="validateField('industry_type')"
                    :placeholder="$fanyi('选择业务类型')"
                    v-model="ops.formregister.industry_type"
                  >
                    <el-option
                      :label="$fanyi('家庭和装饰部门')"
                      :value="$fanyi('家庭和装饰部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('体育部门')"
                      :value="$fanyi('体育部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('健身行业')"
                      :value="$fanyi('健身行业')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('儿童部门')"
                      :value="$fanyi('儿童部门')"
                    ></el-option>

                    <el-option
                      :label="$fanyi('办公室和文具部门')"
                      :value="$fanyi('办公室和文具部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('酒店业')"
                      :value="$fanyi('酒店业')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('宠物/动物部门')"
                      :value="$fanyi('宠物/动物部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('化妆品行业')"
                      :value="$fanyi('化妆品行业')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('技术部门')"
                      :value="$fanyi('技术部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('美容/美发部门')"
                      :value="$fanyi('美容/美发部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('工具和专业部门')"
                      :value="$fanyi('工具和专业部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('汽车行业')"
                      :value="$fanyi('汽车行业')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('时尚与配饰行业')"
                      :value="$fanyi('时尚与配饰行业')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('安全部门')"
                      :value="$fanyi('安全部门')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('多产品部门或集市')"
                      :value="$fanyi('多产品部门或集市')"
                    ></el-option>
                    <el-option
                      :label="$fanyi('其他部门')"
                      :value="$fanyi('其他部门')"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('No fiscal CIF/NIF')"
                  :rules="ops.inputRequireVer"
                  prop="user_id_card"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.user_id_card"
                    :placeholder="$fanyi('Introduce tu CIF/NIF')"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-button
            type="primary"
            class="ContinuarBtn"
            :class="{ isdisabled: btnDisable }"
            @click="!btnDisable ? next() : ''"
            >{{ $fanyi("继续") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
export default {
  data() {
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          //console.log("checkRegisterField", res);
          if (res.success == false) {
            callback(new Error(this.$fanyi("已被使用")));
          } else {
            callback();
          }
        });
    };
    return {
      ops,
      smallStep: 1,
      phoneKeyKey: 23,
      formRules: {
        mobile: [
          {
            required: true,
            message: this.$fanyi("手机号为空"),
            trigger: "blur",
          },

          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
      },

      formRegisterStatus: { mobile: "" }, //选项是否正确输入
    };
  },
  components: {
    mcheckBox,
    mradio,
  },
  computed: {
    btnDisable() {
      let flog = false;
      Object.keys(this.formRegisterStatus).forEach((key) => {
        this.formRegisterStatus[key] == -1 ? (flog = true) : "";
      });
      return (
        !this.ops.formregister.company_name ||
        !this.ops.formregister.user_type ||
        !this.ops.formregister.industry_type ||
        !this.ops.formregister.user_id_card ||
        flog
      );
    },
  },
  created() {},
  methods: {
    validateMethods(prop, status) {
      // console.log(prop, status);
      this.$set(this.formRegisterStatus, prop, status ? 1 : -1);
      //console.log(this.formRegisterStatus[prop]);
    },
    goBack() {
      this.ops.step--;
    },
    next() {
      ops.goNext();
    },
    // 手动触发表单验证
    validateField(type) {
      if (this.ops.formregister[type])
        this.ops.formregister[type] = this.ops.formregister[type].trim();
      this.$refs.formRef.validateField(type, (v) => {});
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  width: 100%;
  @extend .dip;
  .stepCon {
  }
}
</style>
