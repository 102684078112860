var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registerStepConBox"},[_c('div',{staticClass:"stepCon"},[_c('div',{staticClass:"chioseBackBox"},[_vm._m(0),_c('div',{staticClass:"chioseConBox"},[_c('div',{staticClass:"titleBox"},[_c('div',{staticClass:"chioseTitleH1"},[_vm._v(" "+_vm._s(_vm.$fanyi("验证帐户"))+" ")]),_c('div',{staticClass:"chioseTitleFont"},[_vm._v(" "+_vm._s(_vm.$fanyi("请输入我们发送给您的验证码:"))+" "),_c('b',[_vm._v(_vm._s(_vm.ops.formregister.email))])])]),_c('div',{staticClass:"verCodeInputBox"},[_c('div',{staticClass:"inputList",on:{"click":_vm.inputFocu}},_vm._l((6),function(i){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.verCode[i - 1]),expression:"verCode[i - 1]"}],key:i,ref:"inputBoxRef",refInFor:true,staticClass:"inputCss",class:{
                primary: _vm.inputType == 'primary',
                error: _vm.inputType == 'error',
              },attrs:{"type":"text"},domProps:{"value":(_vm.verCode[i - 1])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.verCode, i - 1, $event.target.value)},function($event){return _vm.inputSet(i - 1)}],"paste":function($event){return _vm.inputpaste($event, i - 1)},"keydown":function($event){return _vm.BackspaceMethods(i - 1)}}})}),0),(_vm.inputType == 'error')?_c('div',{staticClass:"errorTipBox"},[_c('img',{attrs:{"src":"https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d8a20358d5.svg","alt":""}}),_vm._m(1)]):_vm._e()]),_c('el-button',{staticClass:"ContinuarBtn",class:{ isdisabled: !_vm.verCodeInputStatus },attrs:{"type":"primary"},on:{"click":_vm.verCodeMe}},[_vm._v(_vm._s(_vm.$fanyi("检查我的验证码")))]),_c('div',{staticClass:"tongyiBox"},[_c('div',{staticClass:"tongYiXieYi  flex acenter",class:{
              agreementError: _vm.agreementType == 'error',
              agreementPrimary: _vm.agreementType == 'primary',
            }},[_c('el-switch',{attrs:{"active-color":"#1A73E8"},on:{"change":function () {
                  _vm.agreement
                    ? (_vm.agreementType = 'primary')
                    : (_vm.agreementType = 'error');
                }},model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}}),_c('span',{staticClass:"xieYiwenzi"},[_vm._v(" He leído y acepto la "),_c('span',{staticClass:"Link",on:{"click":function($event){return _vm.$fun.routerToPage('/politica-privacidad')}}},[_vm._v("política de privacidad")]),_vm._v(" y autorizo el envío de comunicaciones sobre los productos de Rakumart. ")])],1),_c('div',{staticClass:"agreementErrorTip flex acenter",class:{
              agreementError: _vm.agreementType == 'error',
            }},[_c('img',{attrs:{"src":"https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d8a20358d5.svg","alt":""}}),_vm._v(" "+_vm._s(_vm.$fanyi("您必须接受隐私政策"))+" ")])]),(_vm.ops.Ocheck)?_c('div',{staticClass:"tipBox"},[_vm._v(" ¿No has recibido ningún correo electrónico? "),_c('br'),_c('b',{class:{ isdisabled: _vm.ops.Ocheck.Countdown != 60 },on:{"click":function($event){_vm.ops.Ocheck.Countdown == 60 ? _vm.ops.Ocheck.next() : ''}}},[_vm._v(" Pulsa aquí "+_vm._s(_vm.ops.Ocheck.Countdown != 60 ? ("(" + (_vm.ops.Ocheck.Countdown) + "s)") : "")+" ")]),_vm._v(" para que te lo enviemos de nuevo ")]):_vm._e()],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headBox"},[_c('img',{staticClass:"promptIcon",attrs:{"src":"https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f7974ee7fe9.svg","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" El código de verificación que has introducido es incorrecto. Por favor revise el código, o bien "),_c('b',[_vm._v("haz click para recibir otro")]),_vm._v(". ")])}]

export { render, staticRenderFns }