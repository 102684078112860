<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f7974ee7fe9.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("验证帐户") }}
            </div>
            <div class="chioseTitleFont">
              {{ $fanyi("请输入我们发送给您的验证码:") }}
              <b>{{ ops.formregister.email }}</b>
            </div>
          </div>

          <div class="verCodeInputBox">
            <div class="inputList" @click="inputFocu">
              <input
                ref="inputBoxRef"
                type="text"
                @input="inputSet(i - 1)"
                v-model="verCode[i - 1]"
                v-for="i in 6"
                @paste="inputpaste($event, i - 1)"
                :key="i"
                :class="{
                  primary: inputType == 'primary',
                  error: inputType == 'error',
                }"
                class="inputCss"
                @keydown="BackspaceMethods(i - 1)"
              />
            </div>

            <div class="errorTipBox" v-if="inputType == 'error'">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d8a20358d5.svg"
                alt=""
              />
              <span>
                El código de verificación que has introducido es incorrecto. Por
                favor revise el código, o bien
                <b>haz click para recibir otro</b>.
              </span>
            </div>
          </div>

          <el-button
            type="primary"
            class="ContinuarBtn"
            :class="{ isdisabled: !verCodeInputStatus }"
            @click="verCodeMe"
            >{{ $fanyi("检查我的验证码") }}</el-button
          >

          <div class="tongyiBox">
            <div
              class="tongYiXieYi  flex acenter"
              :class="{
                agreementError: agreementType == 'error',
                agreementPrimary: agreementType == 'primary',
              }"
            >
              <el-switch
                v-model="agreement"
                active-color="#1A73E8"
                @change="
                  () => {
                    agreement
                      ? (agreementType = 'primary')
                      : (agreementType = 'error');
                  }
                "
              >
              </el-switch>
              <span class="xieYiwenzi">
                He leído y acepto la
                <span
                  class="Link"
                  @click="$fun.routerToPage('/politica-privacidad')"
                  >política de privacidad</span
                >
                y autorizo el envío de comunicaciones sobre los productos de
                Rakumart.
              </span>
            </div>
            <div
              class="agreementErrorTip flex acenter"
              :class="{
                agreementError: agreementType == 'error',
              }"
            >
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d8a20358d5.svg"
                alt=""
              />
              {{ $fanyi("您必须接受隐私政策") }}
            </div>
          </div>

          <div class="tipBox" v-if="ops.Ocheck">
            ¿No has recibido ningún correo electrónico? <br />
            <b
              :class="{ isdisabled: ops.Ocheck.Countdown != 60 }"
              @click="ops.Ocheck.Countdown == 60 ? ops.Ocheck.next() : ''"
            >
              Pulsa aquí
              {{
                ops.Ocheck.Countdown != 60 ? `(${ops.Ocheck.Countdown}s)` : ""
              }}
            </b>
            para que te lo enviemos de nuevo
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
export default {
  data() {
    return {
      ops,
      verCode: [],
      inputType: "normal", //normal/error/primary

      agreement: false,
      agreementType: "normal", //normal/error/primary
    };
  },
  components: {
    mcheckBox,
    mradio,
  },
  computed: {
    verCodeInputStatus() {
      let num = 0;
      for (let i in this.verCode) {
        if (!!this.verCode[i]) {
          num++;
        }
      }
      if (num >= 6) {
        this.inputType = "primary";
      } else {
        this.inputType = "normal";
      }
      return num >= 6;
    },
  },
  created() {},
  mounted() {
    this.inputFocu();
  },
  methods: {
    inputFocu() {
      for (let i in this.verCode) {
        if (this.verCode[i] == "") {
          this.verCode.splice(i, 1);
          i--;
        }
      }

      this.$refs.inputBoxRef[Math.min(this.verCode.length, 5)].focus();
    },

    goBack() {
      this.ops.step--;
    },
    next() {
      ops.goNext();
    },
    // 输入限制数字和后移光标
    inputSet(i) {
      let num = this.verCode[i];
      num = num.replace(/[^\d]/g, "");
      if (num) {
        num = num.match(/\d$/)[0];
      } // 使用正则表达式匹配最后一个数字
      this.verCode[i] = num;
      if (i < 5 && num) {
        this.$refs.inputBoxRef[i + 1].focus();
      } else {
      }
    },

    bendiInp(i) {
      if (i < 10) {
        this.$refs.ssssss[i + 1].focus();
      } else {
      }
    },

    // 删除前移光标
    BackspaceMethods(i) {
      if (event.key === "Backspace") {
        if (!this.verCode[i] && i != 0) {
          this.verCode.splice(i, 1);
          this.$refs.inputBoxRef[i - 1].focus();
        }
      }
    },

    // 粘贴填充输入框
    inputpaste(event, i) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      // 处理粘贴的内容
      //   console.log("粘贴的内容:", pastedText);
      if (pastedText.length == 1) {
        this.verCode[i] = pastedText;
        this.$forceUpdate();
      } else {
        this.verCode = [];
        let count = 0;
        for (let i = 0; i < pastedText.length && count < 6; i++) {
          if (!isNaN(parseInt(pastedText[i]))) {
            this.verCode[count] = pastedText[i];
            count++;
          }
        }
        this.$forceUpdate();
      }
    },

    // 校验验证码
    verCodeMe() {
      if (!this.agreement) {
        return (this.agreementType = "error");
      }

      let verCodeStr = this.verCode.join("");

      if (!this.verCodeInputStatus) {
        return false;
      }
      // 假如手机号和验证码不为空,就根据手机号和验证码请求接口校验

      this.$api
        .checkVerificationCode({
          occasion: "register",
          number: this.ops.formregister.email,
          code: verCodeStr,
        })
        .then((res) => {
          if (res.success == false) {
            this.$message.error(this.$fanyi(res.msg));
            this.inputType = "error";
          } else {
            this.ops.formregister.verification = {
              number: this.ops.formregister.email,
              code: verCodeStr,
            };
            this.confirmformRegister();
          }
        });
    },

    // 确认注册
    confirmformRegister() {
      if (!this.ops.formregister.country) {
        return this.$message.error(this.$fanyi("必填参数缺失"));
      }

      let list = {};
      for (let i in this.ops.formregister) {
        list[i] = this.ops.formregister[i];
      }

      // 假如是讲师系统推荐链接进入就把邀请码也放进去
      if (this.$route.query.promote) {
        list.promote = this.$route.query.promote;
      }

      list.client_tool = 1;
      list.market_channel = JSON.stringify(list.market_channel);
      this.$api.register(list).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$fun.routerToPage("/register-success");

        sessionStorage.setItem("login_account", list.email);
        sessionStorage.setItem("password", list.password);

        this.ops.goNext();
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  width: 100%;
  // padding-top: 250px;
  @extend .dip;

  .stepCon {
    .chioseTitleH1 {
      margin-bottom: 32px !important;
    }

    .chioseTitleFont {
      margin-bottom: 32px !important;
    }

    .verCodeInputBox {
      margin: 0 auto 32px;
      .inputList {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 0;
        .inputCss {
          pointer-events: none;
          margin: 0 4px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          background: #fff;
          /* Shadows/shadow-xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          display: flex;
          width: 64px;
          min-height: 64px;
          padding: 2px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #d0d5dd;
          text-align: center;
          /* Display lg/Semibold */
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 60px; /* 125% */
          letter-spacing: -0.96px;
        }
        .inputCss.primary {
          border-color: #77b2ff;
          color: #1767d0;
        }
        .inputCss.error {
          border-color: #fda29b;
          color: #d92d20;
        }
      }

      .errorTipBox {
        display: flex;
        align-items: center;
        margin-top: 8px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          color: #f97066;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          font-size: 12px;
        }
        b {
          font-weight: 600;
          color: #d92d20;
        }
      }
    }
  }
  .tongyiBox {
    margin-bottom: 32px;
    .tongYiXieYi {
      border-radius: 8px;
      transition: 0.3s;
      border: 1.5px solid var(--Gray-300, #d0d5dd);
      background: var(--Base-White, #fff);
      width: 100%;
      /* Shadows/shadow-xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 16px;

      .el-switch {
        width: 36px;
        margin-right: 16px;
        /deep/.el-switch__input {
          display: none;
        }
      }

      .xieYiwenzi {
        color: var(--Gray-950, #0c111d);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */

        .Link {
          color: var(--Rakumart-500, #1a73e8);

          /* Text xs/Semibold */
          cursor: pointer;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }

    .tongYiXieYi.agreementError {
      border: 1.5px solid var(--Error-300, #fda29b);
      box-shadow: none;
    }
    .tongYiXieYi.agreementPrimary {
      border: 1.5px solid var(--Error-300, #1767d0);
      box-shadow: none;
    }

    .agreementErrorTip {
      display: none;
      margin-top: 8px;
      color: var(--Error-500, #f04438);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      transition: 0.3s;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .agreementErrorTip.agreementError {
      display: flex;
    }
  }

  .tipBox {
    color: var(--Gray-600, #475467);
    text-align: center;

    /* Text xs/Regular */
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    b {
      color: #1a73e8;
      font-weight: 600;
      cursor: pointer;
    }
    b.isdisabled {
      color: #7c7c7c;
    }
  }
}
</style>
