<template>
  <div class="menuBox flex fdcolmun">
    <div class="logoImgBox"></div>
    <div class="registerStep">
      <div class="stepTitleH2">{{ $fanyi("创建您的Rakumart帐户") }}</div>
      <div class="stepTitleH3">{{ $fanyi("创建您的Rakumart帐户") }}</div>
      <div class="stepOpt flex" :class="{ active: ops.step == 1 }">
        <div class="iconBox">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f24e2381897.svg"
            alt=""
          />
          <div class="verticalLine"></div>
        </div>
        <div class="fontBox">
          <div class="optTitle">{{ $fanyi("个人详细信息") }}</div>
          <div class="optCon">
            {{ $fanyi("输入您的个人信息") }}
          </div>
        </div>
      </div>
      <div class="stepOpt flex" :class="{ active: ops.step == 2 }">
        <div class="iconBox">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f24e1b42b8e.svg"
            alt=""
          />
          <div class="verticalLine"></div>
        </div>
        <div class="fontBox">
          <div class="optTitle">{{ $fanyi("皇后的细节") }}</div>
          <div class="optCon">
            {{ $fanyi("输入您公司的详细信息") }}
          </div>
        </div>
      </div>
      <div class="stepOpt flex" :class="{ active: ops.step == 3 }">
        <div class="iconBox">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14dc70b26.svg
"
            alt=""
          />
          <div class="verticalLine"></div>
        </div>
        <div class="fontBox">
          <div class="optTitle">{{ $fanyi("Rakumart经验") }}</div>
          <div class="optCon">
            {{ $fanyi("填写一个简短的问卷，以个性化您的Rakumart体验") }}
          </div>
        </div>
      </div>
      <div
        class="stepOpt flex"
        :class="{ active: [4, 5, 6].includes(ops.step) }"
      >
        <div class="iconBox">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f24e2082e8e.svg"
            alt=""
          />
          <div class="verticalLine"></div>
        </div>
        <div class="fontBox">
          <div class="optTitle">{{ $fanyi("校验") }}</div>
          <div class="optCon">
            {{ $fanyi("电子邮件，contraseña y telsamufo") }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="goBackBtn flex acenter"
      @click="ops.backLastPage"
      v-if="ops.step != 6"
    >
      <img
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
        alt=""
      />
      <span>{{ $fanyi("回退") }}</span>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
export default {
  data() {
    return { ops };
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.menuBox {
  flex: 0 0 400px;
  min-height: 100vh;
  background: #f9fafb;
  box-shadow: 1px 0px 6px 0px rgba(16, 24, 40, 0.05);
  padding: 40px 43px;

  .logoImgBox {
    width: 184px;
    height: 49.73px;
    margin-bottom: 76px;
    background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f3ddaf42d73.svg");
    background-size: 100%;
  }
  .registerStep {
    .stepTitleH2 {
      color: var(--Gray-950, #0c111d);
      font-size: 18px;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      margin-bottom: 5px;
    }
    .stepTitleH3 {
      color: var(--Gray-500, #667085);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      margin-bottom: 46px;
    }
  }
  .stepOpt {
    margin-bottom: 8px;
    .iconBox {
      width: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;
      img {
        width: 48px;
        height: 48px;
        opacity: 0.5;
      }
      .verticalLine {
        margin: 4px 0;
        border-radius: 2px;
        background: var(--Gray-200, #eaecf0);
        width: 2px;
        flex: 1;
      }
    }
    .fontBox {
      min-height: 76px;
      color: #858c98;
      .optTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
        margin-bottom: 2px;
      }
      .optCon {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
        padding-bottom: 32px;
      }
    }
  }
}
.active {
  .iconBox {
    img {
      opacity: 1 !important;
    }
  }
  .fontBox {
    color: #344054 !important;
  }
}

.goBackBtn {
  margin-top: auto;
  height: 24px;

  img {
    rotate: -90deg;
    width: 24px;
  }
  span {
    margin-left: 8px;
    color: var(--Gray-700, #344054);
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
}
</style>
