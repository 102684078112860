<!-- 注册页 -->
<template>
  <div class="flex registerConBox">
    <!-- 左侧菜单 -->
    <leftMenu />
    <div class="flex cogBgBox fdcolmun jcenter" style="height: 100%;">
      <!-- 内容 -->
      <div class="regfisterRightConBox">
        <el-carousel
          ref="registerBodyCarouselRef"
          height="904px"
          :autoplay="false"
          :interval="0"
          arrow="never"
          :loop="false"
          class="registerBodyCarousel"
          indicator-position="outside"
          v-if="ops.step != 6"
        >
          <el-carousel-item>
            <!-- 个人详细信息 step1 -->
            <personalDetails ref="PersonalDetails" />
          </el-carousel-item>

          <el-carousel-item>
            <!-- 公司的细节 step2 -->
            <detailsOfTheCompany ref="DetailsOfTheCompany" />
          </el-carousel-item>

          <el-carousel-item>
            <!-- Rakumart经验 step3 -->
            <rakumartExperience ref="RakumartExperience" />
          </el-carousel-item>

          <el-carousel-item>
            <!-- 校验 step4 -->
            <ocheck ref="Ocheck" />
          </el-carousel-item>

          <el-carousel-item>
            <!-- 验证码 step5 -->
            <div>
              <verificationCode ref="VerificationCode" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- 防止跳转页面时出现滚动动画放到单独的el-carousel里 -->
        <el-carousel
          ref="registerBodyCarouselRef"
          height="800px"
          :autoplay="false"
          :interval="0"
          arrow="never"
          :loop="false"
          class="registerBodyCarousel"
          indicator-position="outside"
          v-else
        >
          <el-carousel-item>
            <!-- 欢迎视频 step6 -->
            <welcomeVideo ref="WelcomeVideo" />
          </el-carousel-item>
        </el-carousel>
        <div class="stepIconList dip">
          <div
            class="stepBox"
            v-for="item in 4"
            :key="item"
            :class="{
              active: ops.step == item || (item == 4 && ops.step >= 4),
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="rakuYear">
      © Rakumart 2024
    </div>
  </div>
</template>
<script>
import ops from "./utils/opStore.js";
import leftMenu from "./components/model-leftMenu.vue";
import personalDetails from "./components/model-personalDetails.vue";
import detailsOfTheCompany from "./components/model-detailsOfTheCompany.vue";
import rakumartExperience from "./components/model-rakumartExperience.vue";
import ocheck from "./components/model-ocheck.vue";
import verificationCode from "./components/model-verificationCode.vue";
import welcomeVideo from "./components/model-welcomeVideo.vue";
export default {
  data() {
    return { ops };
  },
  components: {
    leftMenu,
    personalDetails,
    detailsOfTheCompany,
    rakumartExperience,
    ocheck,
    verificationCode,
    welcomeVideo,
  },
  watch: {
    "ops.step"(newv, oldv) {
      console.log(newv, oldv, this.ops.step);
      this.$refs.registerBodyCarouselRef.setActiveItem(newv - 1);
    },
  },
  computed: {},
  created() {
    // console.log(ops.state);
    if (this.$route.query.email) {
      this.ops.formregister.email = this.$route.query.email;
    }
    if (this.$route.name == "register-success") {
      this.ops.step = 6;
    }
  },
  mounted() {
    this.ops.PersonalDetails = this.$refs.PersonalDetails;
    this.ops.DetailsOfTheCompany = this.$refs.DetailsOfTheCompany;
    this.ops.RakumartExperience = this.$refs.RakumartExperience;
    this.ops.Ocheck = this.$refs.Ocheck;
    this.ops.VerificationCode = this.$refs.VerificationCode;
    this.ops.WelcomeVideo = this.$refs.WelcomeVideo;
    this.ops.registerBodyCarouselRef = this.$refs.registerBodyCarouselRef;
    console.log(this.$refs.registerBodyCarouselRef);
    this.$nextTick(() => {
      this.$refs.registerBodyCarouselRef.setActiveItem(this.ops.step - 1);
    });
  },
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.registerConBox {
  // height: max-content;
  height: 100vh;
  min-height: 100%;
  overflow: auto;
  background-color: white;
  position: relative;

  .cogBgBox {
    flex: 1;
    height: 100%;
    background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d2a9ed24f4d.svg");
    background-repeat: no-repeat;
    background-position: center 150px;
    background-size: 3000px;
    background-color: white;
  }

  .regfisterRightConBox {
    // padding-bottom: 40px;
  }

  .rakuYear {
    position: absolute;
    right: 100px;
    bottom: 42px;
  }
}

.registerBodyCarousel {
  /deep/.el-carousel__indicators {
    display: none;
  }
  /deep/.el-carousel__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.stepIconList {
  gap: 12px;

  .stepBox {
    width: 30px;
    height: 8px;
    border-radius: 4px;
    background: var(--Gray-200, #eaecf0);
  }
  .active {
    background: var(--Gray-200, #1355ab);
  }
}
</style>
