<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <el-carousel
        ref="elcarousel"
        height="744px"
        :autoplay="false"
        :interval="0"
        arrow="never"
        :loop="false"
        class="carouselBox"
        indicator-position="outside"
      >
        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">{{ $fanyi("商品的用途是什么?") }}</div>
              <div class="singleChoiceList">
                <mradio
                  v-model="ops.formregister.commodity_use"
                  :label="$fanyi('个人/私人使用')"
                  val="De uso personal / Propia"
                />
                <mradio
                  v-model="ops.formregister.commodity_use"
                  :label="$fanyi('分销和销售')"
                  val="Distribución y venta"
                />
              </div>
              <el-button
                type="primary"
                class="ContinuarBtn"
                :class="{ isdisabled: !ops.formregister.commodity_use }"
                @click="ops.formregister.commodity_use ? next() : ''"
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">
                {{ $fanyi("什么样的销售你提供吗?") }}
              </div>
              <div class="singleChoiceList">
                <mradio
                  v-model="ops.formregister.sales_type"
                  :label="$fanyi('批发商')"
                  val="Mayorista"
                />
                <mradio
                  v-model="ops.formregister.sales_type"
                  :label="$fanyi('零售商')"
                  val="Minorista"
                />
              </div>
              <el-button
                type="primary"
                :class="{ isdisabled: !ops.formregister.sales_type }"
                class="ContinuarBtn"
                @click="ops.formregister.sales_type ? next() : ''"
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">
                {{ $fanyi("你的主要销售渠道是什么?") }}
                <span class="chioseTitleH2Font"
                  >{{ $fanyi("您可以选择多个选项") }}
                </span>
              </div>
              <div class="singleChoiceList">
                <mcheckBox
                  v-model="ops.formregister.market_channel"
                  :label="$fanyi('电子商务')"
                  val="E-Commerce"
                />
                <mcheckBox
                  v-model="ops.formregister.market_channel"
                  :label="$fanyi('亚马逊')"
                  val="Amazon"
                />
                <mcheckBox
                  v-model="ops.formregister.market_channel"
                  :label="$fanyi('实体店铺')"
                  val="Tienda física"
                />
                <mcheckBox
                  v-model="ops.formregister.market_channel"
                  :label="$fanyi('其他平台')"
                  val="Otras plataformas"
                />
              </div>
              <el-button
                type="primary"
                :class="{ isdisabled: !ops.formregister.market_channel.length }"
                class="ContinuarBtn "
                @click="ops.formregister.market_channel.length ? next() : ''"
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">
                {{ $fanyi("你每年的进口量是多少?") }}
              </div>
              <div class="singleChoiceList">
                <mradio
                  v-model="ops.formregister.import_amount"
                  :label="'0 - 10K €'"
                  val="0 - 10K €"
                />
                <mradio
                  v-model="ops.formregister.import_amount"
                  :label="'10K - 50K €'"
                  val="10K - 50K €"
                />
                <mradio
                  v-model="ops.formregister.import_amount"
                  :label="'50K - 200K €'"
                  val="50K - 200K €"
                />
                <mradio
                  v-model="ops.formregister.import_amount"
                  :label="'Más de 200K €'"
                  val="Más de 200K €"
                />
              </div>
              <el-button
                type="primary"
                :class="{ isdisabled: !ops.formregister.import_amount }"
                class="ContinuarBtn "
                @click="ops.formregister.import_amount ? next() : ''"
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">
                {{ $fanyi("你从事进口业务多长时间了？") }}
              </div>
              <div class="singleChoiceList">
                <mradio
                  v-model="ops.formregister.import_industry"
                  :label="$fanyi('不到一年')"
                  val="Menos de un año"
                />
                <mradio
                  v-model="ops.formregister.import_industry"
                  :label="$fanyi('超过一年')"
                  val="Más de un año"
                />
              </div>
              <el-button
                type="primary"
                :class="{ isdisabled: !ops.formregister.import_industry }"
                class="ContinuarBtn "
                @click="ops.formregister.import_industry ? next() : ''"
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="chioseBackBox">
            <div class="headBox">
              <img
                class="promptIcon"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670a14d6b9840.svg"
                alt=""
              />
            </div>
            <div class="chioseConBox">
              <div class="titleBox">
                <div class="chioseTitleH1">
                  {{ $fanyi("Rakumart经验") }}
                </div>
                <div class="chioseTitleFont">
                  {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
                </div>
              </div>
              <div class="chioseTitleH2">
                {{ $fanyi("你在哪里认识我们的?") }}
              </div>
              <div class="singleChoiceList">
                <mradio
                  v-model="ops.formregister.experience"
                  label="Tik Tok"
                  val="Tik Tok"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="Instagram"
                  val="Instagram"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="Facebook"
                  val="Facebook"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="YouTube"
                  val="YouTube"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="Formación"
                  val="Formación"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="Recomendación"
                  val="Recomendación"
                />
                <mradio
                  v-model="ops.formregister.experience"
                  label="Otros"
                  val="other"
                />
                <el-input
                  v-if="ops.formregister.experience == 'other'"
                  v-model="otherTxt"
                  :placeholder="$fanyi('你能告诉我们在哪里?')"
                  class="otherInput"
                ></el-input>
              </div>
              <el-button
                type="primary"
                :class="{
                  isdisabled:
                    !ops.formregister.experience ||
                    (ops.formregister.experience == 'other' && !otherTxt),
                }"
                class="ContinuarBtn "
                @click="
                  ops.formregister.experience ||
                  (ops.formregister.experience == 'other' && !otherTxt)
                    ? next()
                    : ''
                "
                >{{ $fanyi("继续") }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
export default {
  data() {
    return {
      ops,
      smallStep: 1,
      otherTxt: "",
    };
  },
  components: {
    mcheckBox,
    mradio,
  },
  computed: {},
  created() {},
  methods: {
    goBack() {
      if (this.smallStep == 1) {
        this.ops.step--;
      } else {
        this.smallStep--;
        this.$refs.elcarousel.setActiveItem(this.smallStep - 1);
      }
    },
    next() {
      if (this.smallStep != 6) {
        this.smallStep++;
        this.$refs.elcarousel.setActiveItem(this.smallStep - 1);
      } else {
        if (this.ops.formregister.experience == "other") {
          this.ops.formregister.experience = this.otherTxt;
        }
        this.ops.step++;
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
</style>
