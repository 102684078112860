<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f3f0d25cbf3.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("个人详细信息") }}
            </div>
            <div class="chioseTitleFont">
              {{ $fanyi("输入您的个人信息") }}
            </div>
          </div>

          <div class="inputList">
            <el-form
              ref="formRef"
              :model="ops.formregister"
              :rules="formRules"
              @validate="validateMethods"
            >
              <div class="formRow">
                <el-form-item
                  :label="$fanyi('名称')"
                  :rules="ops.inputRequireVer"
                  prop="user_name"
                  class="inputOpt userNameBox"
                >
                  <el-input
                    v-model="ops.formregister.user_name"
                    :placeholder="$fanyi('请输入名字')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$fanyi('姓氏')"
                  :rules="ops.inputRequireVer"
                  prop="user_last_name"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.user_last_name"
                    :placeholder="$fanyi('请输入姓氏')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('国家')"
                  :rules="ops.inputRequireVer"
                  prop="nation"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.nation"
                    :placeholder="$fanyi('输入您的公司或企业所在的国家')"
                  ></el-input>
                  <div class="inputTipBox">
                    {{ $fanyi("*我们只从中国进口到西班牙") }}
                  </div>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('省份')"
                  :rules="{
                    required: true,
                    message: $fanyi('此字段为必填项'),
                    trigger: 'blur',
                  }"
                  prop="country"
                  class="inputOpt"
                >
                  <el-select
                    filterable
                    name=""
                    clearable
                    @change="validateField('country')"
                    :placeholder="$fanyi('输入你的省份')"
                    v-model="ops.formregister.country"
                  >
                    <el-option
                      v-for="(ddd, index) in $imdata.provincesList"
                      :key="ddd + index"
                      :label="ddd"
                      :value="ddd"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="formRow">
                <el-form-item
                  :label="$fanyi('人口/城市')"
                  :rules="ops.inputRequireVer"
                  prop="city"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.city"
                    :placeholder="$fanyi('输入您的人口/城市')"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="formRow">
                <el-form-item
                  :label="$fanyi('前缀')"
                  :rules="ops.inputRequireVer"
                  prop="country_phone"
                  class="inputOpt prefixBox"
                >
                  <el-select
                    class="phoneCountrySelect select"
                    :key="phoneKeyKey"
                    @change="
                      phoneKeyKey++; // 如果已经填写了手机号就验证一遍手机号
                      if (ops.formregister.mobile) {
                        validateField('mobile');
                        3;
                      }
                    "
                    filterable
                    v-model="ops.formregister.country_phone"
                  >
                    <el-option
                      v-for="(item, index) in $imdata.countryAndAreaCode"
                      :key="index"
                      :label="item.sxval"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$fanyi('电话')"
                  :rules="formRules.mobile"
                  prop="mobile"
                  class="inputOpt"
                >
                  <el-input
                    v-model="ops.formregister.mobile"
                    oninput="this.value=this.value.replace(/[^\d]/g,'')"
                    :placeholder="$fanyi('输入您的电话号码')"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-button
            type="primary"
            class="ContinuarBtn"
            :class="{ isdisabled: btnDisable }"
            @click="!btnDisable ? next() : ''"
            >{{ $fanyi("继续") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
export default {
  data() {
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          //console.log("checkRegisterField", res);
          if (res.success == false) {
            callback(new Error(this.$fanyi("已被使用")));
          } else {
            callback();
          }
        });
    };
    return {
      ops,
      smallStep: 1,
      phoneKeyKey: 23,
      formRules: {
        mobile: [
          {
            required: true,
            message: this.$fanyi("手机号为空"),
            trigger: "blur",
          },

          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
      },

      formRegisterStatus: { mobile: "" }, //选项是否正确输入
    };
  },
  components: {
    mcheckBox,
    mradio,
  },
  computed: {
    btnDisable() {
      let flog = false;
      Object.keys(this.formRegisterStatus).forEach((key) => {
        this.formRegisterStatus[key] == -1 ? (flog = true) : "";
      });
      return (
        !this.ops.formregister.user_name ||
        !this.ops.formregister.user_last_name ||
        !this.ops.formregister.nation ||
        !this.ops.formregister.country ||
        !this.ops.formregister.city ||
        !this.ops.formregister.country_phone ||
        !this.ops.formregister.mobile ||
        flog
      );
    },
  },
  created() {},
  methods: {
    validateMethods(prop, status) {
      // console.log(prop, status);
      this.$set(this.formRegisterStatus, prop, status ? 1 : -1);
      //console.log(this.formRegisterStatus[prop]);
    },
    goBack() {
      this.$router.go(-1);
    },
    next() {
      ops.goNext();
    },
    // 手动触发表单验证
    validateField(type) {
      if (this.ops.formregister[type])
        this.ops.formregister[type] = this.ops.formregister[type].trim();
      this.$refs.formRef.validateField(type, (v) => {});
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  width: 100%;
  @extend .dip;
  .stepCon {
  }
}
</style>
