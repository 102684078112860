<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f7baf0008e8.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("欢迎来到Rakumart!") }}
            </div>
            <div class="chioseTitleFont">
              {{ $fanyi("足不出户就开始从中国进口") }}
              <b>{{ ops.formregister.email }}</b>
            </div>
          </div>

          <div class="videoBox">
            <video autoplay controls controlsList="nodownload">
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66cef237d6ded.mp4"
                type="video/mp4"
              />
              您的浏览器不支持 video 标签。
            </video>
          </div>

          <el-button type="primary" class="ContinuarBtn" @click="login">{{
            $fanyi("开始")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
export default {
  data() {
    return {
      ops,
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    goBack() {
      this.ops.step--;
    },
    login() {
      this.$api
        .logins({
          login_account: sessionStorage.getItem("login_account"),
          password: sessionStorage.getItem("password"),
          client_tool: 1,
        })
        .then((res) => {
          localStorage.setItem("user_token", res.data.user_token);
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          // sessionStorage.setItem("beginnerTips", true);
          this.$api.EuropegetUserInfo().then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$store.commit("userData", res.data);
            this.$forceUpdate();
            this.$fun.routerToPage("/");
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  @extend .dip;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0 !important;

  .stepCon {
    .chioseTitleFont {
      margin-bottom: 24px !important;
    }
  }
}

.videoBox {
  margin-bottom: 40px;
  video {
    border-radius: 16px;
    width: 782px;
  }
}

.ContinuarBtn {
  margin-bottom: 0 !important;
}

.chioseConBox {
  padding-bottom: 0 !important;
}
</style>
